// css carrucel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import About from "./pages/About";
import Homepage from "./pages/Homepage";
import PropiedadPage from "./pages/PropiedadPage";
import Navbar2 from "./components/Navbar2";
import Footer from "./components/Footer";
import Contacto from "./pages/Contacto";

function App() {
  return (
    <>
      <Router>
        <Navbar2 />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/propiedad/:id" element={<PropiedadPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
