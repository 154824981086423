import React from "react";

import SingleProperti from "./../components/SingleProperti";

const PropiedadPage = () => {
  return (
    <div>
      <SingleProperti />
    </div>
  );
};

export default PropiedadPage;
