import React from "react";
import Carrucel from "./Carrucel";

const SliderPhoto = () => {
  return (
    <div className="mb-5" style={{ overflow: "hidden" }}>
      <Carrucel />
    </div>
  );
};

export default SliderPhoto;
