import { Style } from "@material-ui/icons";
import React from "react";

const NotFoundPAge = () => {
  return (
    <div
      className="text-center d-flex justify-content-center align-items-center "
      style={{ height: "70vh" }}
    >
      <h2 className="display-1"> 404 Pagina no Encontrada</h2>
    </div>
  );
};

export default NotFoundPAge;
