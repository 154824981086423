import {
  Search,
  Chat,
  AccessTime,
  Star,
  Receipt,
  LocalOffer,
} from "@material-ui/icons";
import React from "react";
import "./main.css";
import { redirect } from "react-router-dom";

const Main = ({ onClick, handleChange, handleUrl, match }) => {
  const validacion = () => {
    if (match.length > 7) {
      handleUrl();
    }
  };
  const handleKey = (e) => {
    if (e.keyCode === 13 && match.length > 7) {
      // <redirect to="/#propiedades" />;
      window.scroll(0, 1350);
      validacion();
    }
  };

  return (
    <>
      <main className="curved-container mb-5">
        <div
          className="d-flex align-items-center justify-content-center  flex-column  "
          style={{ height: "100%" }}
        >
          <div className="text-white mb-5  px-1 ">
            <h1 className="font-main-h1 text-center">
              Busca las propiedades de tu preferencia
            </h1>
            <p className="font-main-p text-sm-center px-2 ">
              Añade titulo, palabra, numero o alguna informacion referente a la
              propiedad que estas buscando.
            </p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className=" my-2 my-lg-0 d-flex "
            style={{ width: "70%" }}
          >
            <input
              className="form-control mr-sm-2 p-sm-3"
              type="text"
              required="required"
              placeholder="Search . . . "
              aria-label="Search"
              style={{ fontSize: "1.2rem" }}
              onChange={handleChange}
              onKeyDown={handleKey}
            />
            <button
              className="btn btn-outline-success btn-success  my-sm-0 text-white "
              type="reset"
              onClick={validacion}
            >
              <div className="nav-item nav-link d-flex align-items-center">
                Search
                <Search />
              </div>
            </button>
          </form>
        </div>
        <section className="curved    p-3 mx-auto ">
          <div
            className="d-flex justify-content-evenly gap-3  mt-5"
            style={{ width: "100%" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <AccessTime style={{ fontSize: "2.5rem" }} />
              <p className="text-center font-main-icon">Gestión del tiempo</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Chat style={{ fontSize: "2.5rem" }} />
              <p className="text-center font-main-icon">
                Excelente comunicación
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <Star style={{ fontSize: "2.5rem" }} />
              <p className="text-center font-main-icon">
                Certificación al cliente
              </p>
            </div>
            <div className="d-none d-md-block">
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <Receipt style={{ fontSize: "2.5rem" }} />
                <p className="text-center font-main-icon">
                  Transparencia en gestión
                </p>
              </div>
            </div>
            {/* <div className="d-none d-md-block">
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <LocalOffer style={{ fontSize: "2.5rem" }} />
                <p className="text-center font-main-icon">
                  Promoción de la propiedad
                </p>
              </div>
            </div> */}
          </div>
        </section>
      </main>
      <div></div>
    </>
  );
};

export default Main;
