import React from "react";

const Agentes4 = ({ users }) => {
  return (
    <div>
      <div className=" mx-sm-5 ">
        <div className="d-flex flex-column flex-xl-row ">
          <div className="col ">
            {users.slice(12, 16).map((user, index) => (
              <a
                key={index}
                className="text-decoration-none text-black "
                href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
                target="_blank"
              >
                {user === "success" ? null : (
                  <div className="card " style={{ height: "100%" }}>
                    <div className="card-body bg-slate-100  ">
                      <div className="row">
                        <div className="col d-flex justify-content-center  align-items-center">
                          <img
                            src={
                              !user.photo
                                ? "https://www.asofiduciarias.org.co/wp-content/uploads/2018/06/sin-foto.png"
                                : user.photo
                            }
                            className=" border border-1 border-dark"
                            alt={user.first_name}
                            style={{
                              borderRadius: "50%",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                        </div>
                        <div className="col-8">
                          <h5 className="">
                            {user.first_name} {user.last_name}
                          </h5>
                          <p
                            className="card-text"
                            style={{ fontSize: "1.8vh" }}
                          >
                            <p
                              className="card-text"
                              style={{ fontSize: "1.5vh" }}
                            >
                              {user.about ? (
                                <p>{user.about}</p>
                              ) : (
                                <p>Contactame para mas informacion.</p>
                              )}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agentes4;
