import Propiedad from "./Propiedad";
import Search from "./Search";
import Loader from "./Loader";
import NotResults from "./NotResults";

const Propiedades = ({
  loading,
  setLoading,
  regionFilter,
  handletipoBusquedaChange,
  handleBanos,
  handleHabitaciones,
  setRegion,
  handleChangeRegion,
  handleChangeAgente,
  region,
  setCity,
  properties,
  handleskip,
  handleskipReturn,
  skip,
  users,
  handleUrl,
  agenteFilter,
  setAgenteFilter,
  tipoFilter,
  habitacionFilter,
  banosFilter,
  regionFilter2,
  matchFilter,
  handleReset,
}) => {
  const handleSubir = () => {
    window.scroll(0, 1100);
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        id="propiedades"
        style={{ height: "80vh" }}
      >
        <Loader className="" id="" />
      </div>
    );

  return (
    <>
      <div className=" " id="">
        <div className="mx-4  mx-sm-5 d-flex flex-column flex-lg-row justify-center">
          <div className="col-12   col-sm-12 col-md-auto  ">
            <Search
              className=""
              users={users}
              setLoading={setLoading}
              regionFilter={regionFilter}
              handletipoBusquedaChange={handletipoBusquedaChange}
              handleBanos={handleBanos}
              handleHabitaciones={handleHabitaciones}
              setRegion={setRegion}
              handleChangeRegion={handleChangeRegion}
              handleChangeAgente={handleChangeAgente}
              region={region}
              setCity={setCity}
              handleUrl={handleUrl}
              setAgenteFilter={setAgenteFilter}
              handleReset={handleReset}
            />
          </div>

          {Object.keys(properties).length < 3 ? null : (
            <div className="mx-0 mx-md-5 col ">
              <Propiedad
                properties={properties}
                handleskip={handleskip}
                handleskipReturn={handleskipReturn}
                skip={skip}
                set
                users={users}
                loading={loading}
                agenteFilter={agenteFilter}
                tipoFilter={tipoFilter}
                habitacionFilter={habitacionFilter}
                banosFilter={banosFilter}
                regionFilter2={regionFilter2}
                matchFilter={matchFilter}
              />
              <div className="flex justify-end m-2">
                <button className="btn btn-primary" onClick={handleSubir}>
                  Subir
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Propiedades;
