import React from "react";
import { Link } from "react-router-dom";
import logo from "./img/logo2.png";
import whatsapp from "../components/img/whatsapp.png";
import facebook from "../components/img/facebook.png";
import instagram from "../components/img/instagram.png";
import linkedin from "../components/img/linkedin.png";
import twitter from "../components/img/twitter.png";

const navbar2 = () => {
  return (
    <nav
      // className=" px-3 navbar navbar-expand-lg navbar-dark "
      className=" px-3 navbar navbar-expand-lg navbar-light "
      // style={{ backgroundColor: "#2C3AB7" }}
      // style={{ backgroundColor: "#222747" }}
      style={{ backgroundColor: "#d9d9d9" }}
      // style={{ backgroundColor: "#a7a7a7" }}
    >
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="" className="  " style={{ maxWidth: "250px" }} />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse d-lg-flex justify-content-between "
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav pt-md-4" style={{ fontSize: "1.2rem" }}>
          <Link className="nav-item nav-link active" to="/">
            Home
          </Link>
          <a className="nav-item nav-link " href="/#propiedades">
            Propiedades
          </a>
          <a className="nav-item nav-link" href="/#agentes">
            Agentes
          </a>
          <Link to="/contacto" className="nav-item nav-link ">
            Contacto
          </Link>
        </div>
        <div className="mx-5 nav-contac">
          <div className="d-flex">
            <a
              className="text-black"
              style={{ textDecoration: "none" }}
              href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
              target="_blank"
            >
              <div className=" d-flex flex-column align-items-center ">
                <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
                <span>+507-6525-5567</span>
              </div>
            </a>

            <div className="d-flex flex-column align-items-center justify-content-center">
              <p>
                <b>Respuestas Confiables</b>
              </p>
              <div>
                <a
                  href="https://www.facebook.com/LeonessRealty"
                  target="_blank"
                >
                  <img
                    className="me-1"
                    src={facebook}
                    alt=""
                    style={{ maxWidth: "30px" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/leonessrealty/"
                  target="_blank"
                >
                  <img
                    className="me-1"
                    src={instagram}
                    alt=""
                    style={{ maxWidth: "30px" }}
                  />
                </a>

                <a href="https://twitter.com/LeonessRealty" target="_blank">
                  <img
                    className="me-1"
                    src={twitter}
                    alt=""
                    style={{ maxWidth: "30px" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/leonessrealty/"
                  target="_blank"
                >
                  <img
                    className="me-1"
                    src={linkedin}
                    alt=""
                    style={{ maxWidth: "30px" }}
                  />
                </a>
              </div>
            </div>

            <a
              className="text-black"
              href="https://api.whatsapp.com/send?phone=65255827&text=Hola,%20te%20saluda%20Denisse%20Desgrenger%20de%20Leoness%20Realty,%20en%20qu%C3%A9%20te%20puedo%20ayudar?"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div
                className=" d-flex flex-column align-items-center"
                target="_blank"
              >
                <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
                <span>+507-6525-5827</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default navbar2;
