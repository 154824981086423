import React from "react";

const UsersGrid = ({ users }) => {
  if (users != [])
    return (
      <div
        className="ocultar  "
        style={{ padding: "25px", backgroundColor: "#2C3AB7" }}
      >
        <div className=" px-2 d-none d-sm-block my-5 mx-xl-5">
          <div className="container text-white ">
            <h2
              className="ms-3  text-center mb-5 font-main-h1 "
              // style={{ fontSize: "6.5vh" }}
              // style={{ fontSize: "6.5vh" }}
            >
              Nuestros Agentes
            </h2>
            <p className="  mb-5 font-main-p">
              Brindan un servicio de asesoria profesional con calidad de
              atención personalizada a las necesidades del cliente.
            </p>
          </div>
          <div className=" grid">
            {users.slice(0, -1).map((user, index) => (
              <div
                className="card cardStyle "
                key={index}
                style={{ height: "100%" }}
              >
                <div className="card-body bg-slate-100  align-items-center  ">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col d-flex justify-content-center align-items-center">
                      <img
                        src={
                          !user.photo
                            ? "https://www.asofiduciarias.org.co/wp-content/uploads/2018/06/sin-foto.png"
                            : user.photo
                        }
                        className="card-img-top border border-1 border-dark "
                        alt={user.first_name}
                        style={{
                          borderRadius: "50%",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </div>
                    <div className="col-9 ">
                      <h5 className="">
                        {user.first_name} {user.last_name}
                      </h5>
                      <p className="card-text" style={{ fontSize: "1.5vh" }}>
                        {user.about ? (
                          <p>{user.about}</p>
                        ) : (
                          <p>Contactame para mas informacion.</p>
                        )}
                      </p>
                      <p style={{ fontSize: "1.5vh" }}>
                        <b>{user.email}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
};

export default UsersGrid;
