import React, { Component } from "react";
import Slider from "react-slick";
import Agentes from "./Agentes";
import Agentes2 from "./Agentes2";
import Agentes3 from "./Agentes3";
import Agentes4 from "./Agentes4";

const SimpleSlider = ({ users }) => {
  const settings = {
    dots: true,
    // fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div
      className="px-2 agentesSlider "
      style={{ overflow: "hidden", backgroundColor: "#2C3AB7" }}
    >
      <div className="container text-white ">
        <h2 className="ms-3 text-center my-4"> Nuestros Agentes</h2>
        <p className="  mb-4 font-main-p">
          Brindan un servicio de asesoria profesional con calidad de atención
          personalizada a las necesidades del cliente.
        </p>
      </div>
      <Slider {...settings} className=" mb-5">
        {users.slice(0, -1).length > 0 ? (
          <div>
            <Agentes users={users} />
          </div>
        ) : null}
        {users.slice(0, -1).length > 4 ? (
          <div>
            <Agentes2 users={users} />
          </div>
        ) : null}
        {users.slice(0, -1).length > 8 ? (
          <div>
            <Agentes3 users={users} />
          </div>
        ) : null}
        {users.slice(0, -1).length > 12 ? (
          <div>
            <Agentes4 users={users} />
          </div>
        ) : null}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
