import React, { useEffect, useState } from "react";
import Sanitized from "../sanitize.js";
import "./main.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import Loader from "./Loader";
import { SkipNext, SkipPrevious, WhatsApp } from "@material-ui/icons";

function SamplePrevArrow({ onClick }) {
  return (
    <div className="arrow arrow-left" onClick={onClick}>
      <SkipPrevious style={{ fontSize: "3rem" }} />
    </div>
  );
}
function SampleNextArrow({ onClick }) {
  return (
    <div className="arrow arrow-right" onClick={onClick}>
      <SkipNext style={{ fontSize: "3rem" }} />
    </div>
  );
}
// const urlUsers = `https://mycorsproxyfix.herokuapp.com/https://api.wasi.co/v1/user/all-users?`;
const urlUsers = `https://propiedades.eduaprendes.com/api.wasi.co/v1/user/all-users?`;
// https://propiedades.eduaprendes.com/api.wasi.co/v1/location/regions-from-country/124?quantity=true

const SingleProperti = () => {
  window.scrollTo(0, 0);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  //   console.log(params);
  const id_propertypage = params.id;

  const settings = {
    dots: true,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    const peticion = `https://propiedades.eduaprendes.com/api.wasi.co/v1//property/get/${id_propertypage}`;
    const getData = async () => {
      const res = await axios.post(peticion, process.env.REACT_APP_WASI_TOKEN);
      setData(res.data);

      const resUsers = await axios.post(
        urlUsers,
        process.env.REACT_APP_WASI_TOKEN
      );
      setUsers(Object.values(resUsers.data));
      setLoading(false);
      // console.log(res.data);
    };

    getData();
  }, []);

  const finduserFullName = (id) => {
    if (users.length > 0) {
      const res = users.find((user) => user.id_user === id);
      // console.log(res);
      return res.first_name + " " + res.last_name;
    }
  };

  const finduserUrlWhatsapp = (id) => {
    if (users.length > 0) {
      const res = users.find((user) => user.id_user === id);
      // console.log(res);
      return res.twitter;
    }
  };
  // if (loading) return <h1>Loading</h1>;
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Loader className="" />
      </div>
    );

  // aca buscamos las imagenes de la Api , y luego creamos un nuevo arreglo para quitar una propiedad que no  es imagen de la lista
  const images = data.galleries[0];
  const arregloImages = Object.values(images).slice(0, -1);
  // console.log(arregloImages);

  return (
    <div className="bg-dark" style={{ overflow: "hidden" }}>
      <div className="container ">
        <div className=" rounded">
          <div className="  p-sm-5   ">
            <div className="d-flex justify-content-between flex-column  flex-lg-row mb-2 pt-3">
              <h3 className="text-white font-singleproperti-h3">
                {data.title}
              </h3>
              <div>
                <a
                  href={finduserUrlWhatsapp(data.id_user)}
                  target="_blank"
                  className="  mb-0 bg-warning p-2 rounded text-decoration-none text-black"
                >
                  Agente:
                  <b>
                    {finduserFullName(data.id_user)}{" "}
                    <WhatsApp style={{ fontSize: "2rem" }} />
                  </b>
                </a>
              </div>
            </div>

            <div className="border rounded bg-light">
              <Slider {...settings} className="">
                {Object.values(arregloImages).map((item, index) => (
                  <div key={index}>
                    <div className="mx-auto singleimage d-flex justify-content-center">
                      <img
                        src={item.url}
                        alt=""
                        className="img-fluid  "
                        // style={{ height: "500px" }}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
              <div className=" d-flex justify-content-around mt-5 bg-dark text-white align-items-center">
                <h4 className="ms-5">
                  {data.city_label}, {data.region_label}
                </h4>
                {data.for_sale === "true" ? (
                  <p className="bg-danger  my-auto p-2  ">
                    En Venta ${data.sale_price_label.slice(3)} USD
                  </p>
                ) : (
                  <p className="bg-danger  my-auto p-2  ">
                    Alquiler ${data.rent_price_label.slice(3)} USD
                  </p>
                )}
              </div>

              <div className="container">
                {/* la data viene con etiquetas html, con Sanitized podemos interpretar las etiquetas html en la interfaz */}
                <div className="my-4 px-5">
                  <Sanitized html={data.observations} />
                </div>
                <h3
                  className="text-center bg-black m-0 p-1 text-white mx-auto rounded-top "
                  style={{ maxWidth: "800px" }}
                >
                  Detalles de Propiedad
                </h3>
                <ul
                  className=" container-sm d-flex  flex-column flex-sm-row justify-content-evenly  py-3  text-white rounded-bottom "
                  style={{
                    maxWidth: "800px",
                    backgroundColor: "rgb(100, 100, 100)",
                  }}
                >
                  <div>
                    <li className="list-group-item">
                      Tipo de propiedad
                      {data.id_property_type === 1 ? (
                        <p className="ms-3">Casa</p>
                      ) : data.id_property_type === 2 ? (
                        <p className="ms-3">Apartamento</p>
                      ) : data.id_property_type === 3 ? (
                        <p className="ms-3">Local Comercial</p>
                      ) : data.id_property_type === 4 ? (
                        <p className="ms-3">Oficina</p>
                      ) : data.id_property_type === 5 ? (
                        <p className="ms-3">Lote / Terreno</p>
                      ) : data.id_property_type === 6 ? (
                        <p className="ms-3">Lote Comercial</p>
                      ) : data.id_property_type === 7 ? (
                        <p className="ms-3">Finca</p>
                      ) : data.id_property_type === 8 ? (
                        <p className="ms-3">Bodega</p>
                      ) : data.id_property_type === 9 ? (
                        <p className="ms-3">Chalet</p>
                      ) : data.id_property_type === 10 ? (
                        <p className="ms-3">Casa de Campo</p>
                      ) : data.id_property_type === 11 ? (
                        <p className="ms-3">Hoteles</p>
                      ) : data.id_property_type === 12 ? (
                        <p className="ms-3">Finca - Hoteles</p>
                      ) : data.id_property_type === 14 ? (
                        <p className="ms-3">Aparta-Estudio</p>
                      ) : data.id_property_type === 15 ? (
                        <p className="ms-3">Consultorio</p>
                      ) : data.id_property_type === 16 ? (
                        <p className="ms-3">Edificio</p>
                      ) : data.id_property_type === 17 ? (
                        <p className="ms-3">Lote de Playa</p>
                      ) : data.id_property_type === 18 ? (
                        <p className="ms-3">Hostal</p>
                      ) : data.id_property_type === 19 ? (
                        <p className="ms-3">Condominio</p>
                      ) : data.id_property_type === 20 ? (
                        <p className="ms-3">Duplex</p>
                      ) : data.id_property_type === 21 ? (
                        <p className="ms-3">Atico</p>
                      ) : data.id_property_type === 22 ? (
                        <p className="ms-3">Bungalow</p>
                      ) : data.id_property_type === 23 ? (
                        <p className="ms-3">Galpon Industrial</p>
                      ) : data.id_property_type === 24 ? (
                        <p className="ms-3">Casa de Playa</p>
                      ) : data.id_property_type === 25 ? (
                        <p className="ms-3">Piso</p>
                      ) : data.id_property_type === 26 ? (
                        <p className="ms-3">Garaje</p>
                      ) : data.id_property_type === 27 ? (
                        <p className="ms-3">Cortijo</p>
                      ) : data.id_property_type === 28 ? (
                        <p className="ms-3">Cabañas</p>
                      ) : data.id_property_type === 29 ? (
                        <p className="ms-3">Isla</p>
                      ) : data.id_property_type === 30 ? (
                        <p className="ms-3">Nave Industrial</p>
                      ) : data.id_property_type === 31 ? (
                        <p className="ms-3">Campos, Chacras y Quintas</p>
                      ) : data.id_property_type === 32 ? (
                        <p className="ms-3">Terreno</p>
                      ) : (
                        <p className="ms-3">Agente: Por Registrar</p>
                      )}
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Referencia <p className="ms-3"> {data.id_property}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Tarifa de mantenimiento
                      <p className="ms-3"> {data.maintenance_fee_label}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Habitaciones <p className="ms-3"> {data.bedrooms}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Baños <p className="ms-3"> {data.bathrooms}</p>
                    </li>
                    <hr />
                  </div>
                  <div>
                    <li className="list-group-item">
                      Garaje<p className="ms-3"> {data.garages}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Pisos<p className="ms-3"> {data.floor || 1}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Fecha de construcción{" "}
                      <p className="ms-3">{data.building_date}</p>
                    </li>
                    <hr />
                    <li className="list-group-item">
                      Area <p className="ms-3">{data.area} (m2)</p>
                    </li>
                    <hr />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProperti;
