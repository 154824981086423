import { Link } from "react-router-dom";

const Propiedad = ({
  properties,
  users,
  agenteFilter,
  tipoFilter,
  habitacionFilter,
  banosFilter,
  regionFilter2,
  matchFilter,
}) => {
  const finduser = (id) => {
    const res = users.find((user) => user.id_user === id);
    return res.first_name + " " + res.last_name;
  };

  return (
    <div className=" " id="propiedades">
      <h2 className=" ">Resultados de tu busqueda</h2>

      <p className="">
        {agenteFilter ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {agenteFilter}
          </button>
        ) : null}
        {tipoFilter ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {tipoFilter}
          </button>
        ) : null}
        {habitacionFilter ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {habitacionFilter}
          </button>
        ) : null}
        {banosFilter ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {banosFilter}
          </button>
        ) : null}
        {regionFilter2 ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {regionFilter2}
          </button>
        ) : null}
        {matchFilter ? (
          <button className="btn btn-primary ms-1 my-2 pointer-events-none">
            {matchFilter}
          </button>
        ) : null}
      </p>

      <>
        {/* <div className=" grid  md:grid-cols-2  xl:grid-cols-3 2xl:grid-cols-4 gap-4"> */}
        <div className="   ">
          <div className="row">
            {properties.slice(0, -2).map((item, index) => (
              <div
                key={index}
                className=" col-12 col-xxl-3 col-xl-4 col-md-6  mb-4 p-0 px-1"
              >
                {
                  /* APARTIR DE AQUI HAY QUE PONER ATENCION */
                  <div
                    className="card mx-auto shadow-sm cardProperty "
                    // style={{ height: "100%" }}
                  >
                    <p className="mb-0 bg-warning p-2 text-start ">
                      Agente: <b>{finduser(item.id_user)}</b>
                    </p>

                    <div
                      className="position-relative  text-center "
                      style={{ height: "200px" }}
                    >
                      <Link to={`/propiedad/${item.id_property}`}>
                        <img
                          src={item.main_image?.url_original}
                          alt=""
                          // className="card-img-top    img-xs-fluid img-grid"
                          className="img-propiedad"
                        />
                      </Link>
                      <div className="bg-danger rounded-bottom d-inline  p-2 position-absolute top-0  end-0 text-white mt-0">
                        {item.for_rent === "false" ? (
                          <div>${item.sale_price_label.slice(3)}</div>
                        ) : item.for_rent === "true" ? (
                          <div>${item.rent_price_label.slice(3)}</div>
                        ) : (
                          <h1></h1>
                        )}
                      </div>
                    </div>

                    <div
                      className="card-title text-bg-secondary p-2 mb-0"
                      style={{ height: "70px" }}
                    >
                      {item.title}
                    </div>
                    <div className="text-center  ">
                      Region: {item.region_label}
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-evenly">
                        <li className="list-group-item  ">
                          Habitaciones: {item.bedrooms}
                        </li>
                        <li className="list-group-item ">
                          Baños: {item.bathrooms}
                        </li>
                      </div>

                      <Link to={`/propiedad/${item.id_property}`}>
                        <div className="btn btn-primary d-block m-2">
                          Mas Info
                        </div>
                      </Link>
                    </div>
                  </div>
                }
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default Propiedad;
