import React from "react";
// import image1 from "./img/image1.png";
import map from "../components/img/map.png";

const Contacto = () => {
  return (
    <div className="container mb-3">
      <div className="row d-flex flex-column-reverse mt-5x1   flex-md-row  justify-content-center align-items-center ">
        <div className=" col col-12 col-md-6  d-flex justify-content-center align-itemes-center my-4">
          <a href="https://www.google.com/maps/place/Leoness+Realty,+S.A/@8.983554,-79.5244522,15z/data=!4m5!3m4!1s0x0:0xf6f5c472cbd5680a!8m2!3d8.983554!4d-79.52447">
            <img className="img-fluid " src={map} alt="" />
          </a>
        </div>
        <div
          className="col   d-flex flex-column justify-content-center align-items-center "
          // style={{ backgroundColor: "#f2f2f2" }}
        >
          <h3 className="text-uppercase fw-bold mb-4 text-primary text-center ">
            Contáctanos
          </h3>
          <p className="text-center">
            <i className="fas fa-home me-3 text-secondary font-main-p "></i>
            Dirección: 12, Samuel Lewis, PH COMOSA, Panamá
          </p>
          <p>
            <i className="fas fa-envelope me-3 text-secondary font-main-p"></i>
            juanseverino@leonessrealty.com
          </p>
          <p>
            <i className="fas fa-phone me-3 text-secondary font-main-p"></i>
            Telf: +507 6525-5567
          </p>
          <p>
            <i className="fas fa-phone me-3 text-secondary font-main-p"></i>
            Telf: + 507 6525-5827
          </p>
          <p>
            <i className="fas fa-phone me-3 text-secondary font-main-p"></i>
            Telf: +507-300-0099
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
