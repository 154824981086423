import styled from "styled-components";
import media from "styled-media-query";
import whatsapp from "../components/img/whatsapp.png";

const Container = styled.div`
  /* height: 30px; */
  background-color: rgb(10, 160, 32);
  background-color: #000000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 14px;
  font-weight: 500;

  ${media.greaterThan("1212px")`
   display:none ;
    
  `}
`;

const Container1 = styled.div`
  height: 30px;
  background-color: rgb(10, 160, 32);
  background-color: #000000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  ${media.lessThan("1212px")`
    /* screen width is between 768px (medium) and 1170px (large) */
    display:none ;
  `}
`;

const Text = styled.span``;

const Announcement = () => {
  // return <Container>Tu mejor opcion de propiedades en Panama</Container>;
  return (
    <>
      <Container1>
        <Text>Tu mejor opcion de propiedades en Panama</Text>
      </Container1>

      <Container>
        <a
          className="text-white"
          href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className=" d-flex flex-column align-items-center py-1  ">
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
            <span className="font-Announcement-text">+507-6525-5567</span>
          </div>
        </a>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span className="">
            <b>Respuestas Confiables</b>
          </span>
          {/* <div>
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
          </div> */}
        </div>
        <a
          className="text-white"
          href="https://api.whatsapp.com/send?phone=65255827&text=Hola,%20te%20saluda%20Denisse%20Desgrenger%20de%20Leoness%20Realty,%20en%20qu%C3%A9%20te%20puedo%20ayudar?"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className=" d-flex flex-column align-items-center ">
            <img src={whatsapp} alt="" style={{ maxWidth: "30px" }} />
            <span className="font-Announcement-text"> +507-6525-5827</span>
          </div>
        </a>
      </Container>
    </>
  );
};

export default Announcement;
