import React from "react";

const Search = ({
  users,
  setLoading,
  regionFilter,
  handletipoBusquedaChange,
  handleBanos,
  handleHabitaciones,
  handleChangeRegion,
  handleChangeAgente,
  setRegion,
  region,
  setCity,
  handleUrl,
  handleReset,
}) => {
  return (
    <>
      <h3
        className=" text-white d-block d-lg-none p-1 mb-0 "
        style={{ backgroundColor: "#2C3AB7" }}
      >
        Opciones de Filtro
      </h3>
      <div className="  searchForm ">
        <form
          className="container my-mg-5 "
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <div className="form-group my-3 ">
              <label htmlFor="disabledTextInput">Agentes</label>
              <select
                className="form-select "
                aria-label="Habitaciones"
                onChange={handleChangeAgente}
              >
                <option defaultValue value="">
                  Selecciona
                </option>
                {users.slice(0, -1).map((user, index) => (
                  <option key={index} value={`&id_user=${user.id_user}`}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}

                {/* <option value="">Todos</option> */}
              </select>
            </div>
            <div className="form-group my-3">
              <label htmlFor="disabledTextInput">¿Venta o Alquiler?</label>
              <select
                className="form-select"
                aria-label="¿Que Buscas?"
                onChange={handletipoBusquedaChange}
              >
                <option defaultValue value="">
                  Selecciona
                </option>
                <option value="&for_sale=true">Venta</option>
                <option value="&for_rent=true">Alquiler</option>
                {/* <option value="&for_transfer=true">Transferir</option> */}
              </select>
            </div>

            <div className="form-group my-3">
              <label htmlFor="disabledTextInput">Habitaciones min</label>
              <select
                className="form-select"
                aria-label="Habitaciones"
                onChange={handleHabitaciones}
              >
                <option defaultValue value="">
                  Selecciona
                </option>
                <option value="&min_bedrooms=1">1</option>
                <option value="&min_bedrooms=2">2</option>
                <option value="&min_bedrooms=3">3</option>
                <option value="&min_bedrooms=4">4</option>
                {/* <option value="&min_bedrooms=5">5</option>
              <option value="&min_bedrooms=6">6</option>
              <option value="&min_bedrooms=7">7</option>
              <option value="&min_bedrooms=8">8</option>
              <option value="&min_bedrooms=9">9</option>
              <option value="&min_bedrooms=10">10</option> */}
              </select>
            </div>
            <div className="form-group my-3">
              <label htmlFor="disabledTextInput">Baños min</label>
              <select
                className="form-select"
                aria-label="Baños"
                onChange={handleBanos}
              >
                <option defaultValue value="">
                  Selecciona
                </option>
                <option value="&bathrooms=1">1</option>
                <option value="&bathrooms=2">2</option>
                <option value="&bathrooms=3">3</option>
                <option value="&bathrooms=4">4</option>
                {/* <option value="&bathrooms=5">5</option>
              <option value="&bathrooms=6">6</option>
              <option value="&bathrooms=7">7</option>
              <option value="&bathrooms=8">8</option>
              <option value="&bathrooms=9">9</option>
              <option value="&bathrooms=10">10</option> */}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="disabledTextInput">Region</label>
              <select
                className="form-select"
                aria-label="Habitaciones"
                onChange={handleChangeRegion}
              >
                <option defaultValue value="">
                  Selecciona
                </option>
                {regionFilter.map((region) => (
                  <option
                    key={region.id_region}
                    value={`&id_region=${region.id_region}`}
                  >
                    {region.name}
                  </option>
                ))}
                <option value="">todos</option>
              </select>
            </div>

            <div className="my-3">
              <a
                className="btn btn-primary px-3"
                // href="/#propiedades"
                onClick={handleUrl}
              >
                Buscar
              </a>

              <button
                onClick={handleReset}
                className="btn btn-danger mx-1"
                href="/"
              >
                Reset
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default Search;
