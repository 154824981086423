import { FindInPageOutlined } from "@material-ui/icons";
import React from "react";

const NotResults = () => {
  return (
    <div className="   d-flex flex-column align-items-center justify-content-center col">
      <h4 className="text-center">Fin de Resultados / Sin Coincidencia</h4>

      <FindInPageOutlined className="mt-3" style={{ fontSize: "6rem" }} />
      <p>Prueba con otro dato.</p>
      {/* <button type="submit" className="btn btn-primary my-3 ">
        <a className="text-white " href="/" style={{ textDecoration: "none" }}>
          Reiniciar Busqueda
        </a>
      </button> */}
    </div>
  );
};

export default NotResults;
