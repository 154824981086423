import {
  Facebook,
  Instagram,
  Twitter,
  WhatsApp,
  LinkedIn,
} from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import logo from "./img/logo2.png";

const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start  text-black   "
      style={{ backgroundColor: "#d9d9d9" }}
    >
      {/* <section className="d-flex justify-content-center justify-content-lg-center p-4    ">
        <div className="me-5 d-none d-lg-block ">
          <span>Siguenos en nuestras redes sociales</span>
        </div>
        <div>
          <a href="" className="me-4 link-secondary">
            <Facebook className="text-black" />
          </a>
          <a href="" className="me-4 link-secondary">
            <Twitter className="text-black" />
          </a>

          <a href="" className="me-4 link-secondary">
            <Instagram className="text-black" />
          </a>
        </div>
      </section> */}

      <section className=" py-4 ">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img
                src={logo}
                alt=""
                className="  "
                style={{ maxWidth: "300px" }}
              />
              <p className="text-center">
                Empresa lider con 15 años de experiencia en el mercado
                inmobiliario de Panamá. Brindando apoyo y soluciones a las
                necesidades de nuestros clientes <br /> <br />
                <b> No. de Licencia PJ-1401-2022</b>
              </p>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Navegacion</h6>
              <p>
                <a href="/" className="text-reset">
                  Home
                </a>
              </p>
              <p>
                <a href="/#propiedades" className="text-reset">
                  Propiedades
                </a>
              </p>
              <p>
                <a href="/#agentes" className="text-reset">
                  Agentes
                </a>
              </p>
              <p>
                <Link to="/contacto" className="text-reset">
                  Contacto
                </Link>
              </p>
            </div>

            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Orders
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </div> */}

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 text-center">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <i className="fas fa-home me-3 text-secondary text-center"></i>
                Dirección: 12, Samuel Lewis, PH COMOSA, Panamá
              </p>
              <p>
                <i className="fas fa-envelope me-3 text-secondary"></i>
                juanseverino@leonessrealty.com
              </p>

              <a
                className="text-black"
                href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div>
                  <i className="fas fa-phone me-3 text-secondary"></i>
                  <WhatsApp style={{ fontSize: "2rem" }} />
                  Telf: +507 6525-5567
                </div>
              </a>
              <div className="my-2">
                <span className="">
                  <b>Respuestas confiables</b>
                </span>
              </div>

              <a
                className="text-black"
                href="https://api.whatsapp.com/send?phone=65255827&text=Hola,%20te%20saluda%20Denisse%20Desgrenger%20de%20Leoness%20Realty,%20en%20qu%C3%A9%20te%20puedo%20ayudar?"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div>
                  <i className="fas fa-phone me-3 text-secondary"></i>
                  <WhatsApp style={{ fontSize: "2rem" }} />
                  Telf: + 507 6525-5827
                </div>
              </a>

              <p>
                <b>juanseverino@leonessrealty.com</b>
              </p>
              <div className=" d-flex justify-content-center  ms-3">
                <a
                  href="https://www.facebook.com/LeonessRealty"
                  target="_blank"
                  className="me-4 link-secondary "
                >
                  <div>
                    <Facebook
                      className="text-black  ms-1"
                      style={{ fontSize: "2.5rem" }}
                    />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/leonessrealty/"
                  target="_blank"
                  className="me-4 link-secondary"
                >
                  <Instagram
                    className="text-black m-0 p-0"
                    style={{ fontSize: "2.5rem" }}
                  />
                </a>
                <a
                  href="https://twitter.com/LeonessRealty"
                  target="_blank"
                  className="me-4 link-secondary"
                >
                  <Twitter
                    className="text-black"
                    style={{ fontSize: "2.5rem" }}
                  />
                </a>

                <a
                  href="https://www.linkedin.com/in/leonessrealty/"
                  target="_black"
                  className="me-4 link-secondary "
                >
                  <div>
                    <LinkedIn
                      className="text-black  ms-1"
                      style={{ fontSize: "2.5rem" }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="text-center">
        <WhatsApp style={{ fontSize: "2rem" }} />
        <p>
          <b>Respuestas confiables</b>
        </p>
      </div> */}

      <div className="text-center p-4">
        © 2022 Copyright:
        <a
          className="text-reset fw-bold"
          href="https://www.leonessrealtysa.com/"
        >
          leonessrealtysa.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;
