import React from "react";
import "./main.css";
import Slider from "react-slick";
import image1 from "./img/image1.png";
import image2 from "./img/image2.png";
import image3 from "./img/image3.png";
import image4 from "./img/image4.png";
import image5 from "./img/image5.png";
import image6 from "./img/image6.png";

const Carrucel = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="carrucel ">
      <div className=" container d-flex flex-column flex-lg-row justify-content-center  mx-auto my-5 ">
        <div className="col d-flex flex-column justify-content-center align-items-center ">
          <h2
            className="text-center font-carrucel-h2"
            style={{ color: "rgb(34, 34, 175)" }}
          >
            <strong>Adquiere la casa</strong>
          </h2>
          <h3 className="text-center font-carrucel-h3">de tus sueños</h3>
          <p className="font-carrucel-p text-center ">
            Te apoyamos con las solicitudes para adquirir tu propiedad.
          </p>
        </div>
        <div className="mx-auto col-12 col-lg-6   ">
          <Slider {...settings} className="">
            <div className="">
              <img
                src={image1}
                alt=""
                className=" mx-auto "
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <img
                src={image2}
                alt=""
                className="mx-auto"
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <img
                src={image3}
                alt=""
                className="mx-auto"
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <img
                src={image4}
                alt=""
                className="mx-auto"
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <img
                src={image5}
                alt=""
                className="mx-auto"
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <img
                src={image6}
                alt=""
                className="mx-auto"
                style={{ width: "100%" }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Carrucel;
