import { useState, useEffect } from "react";
import Propiedades from "../components/Propiedades";
import Main from "./../components/Main";
import SliderPhoto from "./../components/SliderPhoto";
import Announcement from "./../components/Announcement";
import SimpleSlider from "../components/AgentesSlicks";
import axios from "axios";
import Arrows from "./../components/Arrows";
import { Link } from "react-scroll";
import { PaginatedItems } from "../components/Pagination";
import UsersGrid from "../components/AgentesGrid";
import whatsapp from "../components/img/whatsapp.png";

const Homepage = () => {
  const initialUrl = `https://propiedades.eduaprendes.com/api.wasi.co/v1/property/search?&take=12`;
  const [url, setUrl] = useState(initialUrl);
  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [tipoBusqueda, setTipoBusqueda] = useState("");
  const [habitacion, setHabitacion] = useState("");
  const [banos, setBanos] = useState("");
  const [preMatch, setPreMatch] = useState("");
  const [match, setMatch] = useState("");
  const [agente, setAgente] = useState("");
  const [agenteFilter, setAgenteFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");
  const [habitacionFilter, setHabitacionFilter] = useState("");
  const [banosFilter, setBanosFilter] = useState("");
  const [regionFilter2, setRegionFilter2] = useState("");
  const [matchFilter, setMatchFilter] = useState("");

  // const urlProperties = `https://mycorsproxyfix.herokuapp.com/https://api.wasi.co/v1/property/search?&take=9`;
  const urlUsers = `https://propiedades.eduaprendes.com/api.wasi.co/v1/user/all-users?`;

  const regionUrl =
    "https://propiedades.eduaprendes.com/api.wasi.co/v1/location/regions-from-country/124?quantity=true";

  const handleReset = () => {
    window.scroll(0, 1000);
    // setLoading(true);
    setUrl(initialUrl);
    setAgente("");
    setTipoBusqueda("");
    setHabitacion("");
    setBanos("");
    setRegion("");
    setAgenteFilter("");
    setTipoFilter("");
    setHabitacionFilter("");
    setBanosFilter("");
    setRegionFilter2("");
    setMatchFilter("");
  };

  const handleUrl = () => {
    setUrl(
      initialUrl + agente + tipoBusqueda + habitacion + banos + region + match
    );
    setLoading(true);

    setMatch("");

    window.scroll(0, 1100);

    if (match) {
      setMatchFilter("Barra busq: " + match.slice(7));
    }

    if (agente) {
      const agenteFiltrado = users.find(
        (user) => user.id_user === Number(agente.slice(9))
      );
      setAgenteFilter(
        "Agente:" + agenteFiltrado.first_name + " " + agenteFiltrado.last_name
      );
    }

    if (tipoBusqueda === "&for_sale=true") {
      setTipoFilter("Venta");
    }
    if (tipoBusqueda === "&for_rent=true") {
      setTipoFilter("Alquiler");
    }

    if (habitacion) {
      setHabitacionFilter("Habitacion min: " + habitacion.slice(14));
    }

    if (banos) {
      setBanosFilter("Baños min: " + banos.slice(11));
    }

    if (regionFilter != []) {
      const regionFiltrada = regionFilter.find(
        (item) => item.id_region === Number(region.slice(11))
      );
      setRegionFilter2("Region: " + regionFiltrada.name);
    }
  };

  function handletipoBusquedaChange(e) {
    setTipoBusqueda(e.target.value);
  }
  function handleBanos(e) {
    setBanos(e.target.value);
  }

  function handleHabitaciones(e) {
    setHabitacion(e.target.value);
  }

  function handleChangeRegion(e) {
    setRegion(e.target.value);
  }

  function handleChangeAgente(e) {
    setAgente(e.target.value);
  }

  const handleChange = (e) => {
    setMatch("&match=" + e.target.value);
  };

  function onClick(e) {
    setMatch(preMatch);
  }

  useEffect(() => {
    const getData = async () => {
      const resProperties = await axios.post(
        url,
        process.env.REACT_APP_WASI_TOKEN
      );
      setProperties(Object.values(resProperties.data));

      const resUsers = await axios.post(
        urlUsers,
        process.env.REACT_APP_WASI_TOKEN
      );
      setUsers(Object.values(resUsers.data));

      setLoading(false);
    };
    getData();
  }, [url]);

  useEffect(() => {
    const getRegion = async () => {
      const resRegion = await axios.post(
        regionUrl,
        process.env.REACT_APP_WASI_TOKEN
      );

      if (resRegion) {
        const filtrado = Object.values(resRegion.data).filter(
          (region) => region.quantity > 0
        );

        setRegionFilter(filtrado);
      }
    };
    getRegion();
  }, []);

  return (
    <div className="">
      <Announcement />
      <Main
        onClick={onClick}
        handleChange={handleChange}
        handleUrl={handleUrl}
        match={match}
      />
      <SliderPhoto className="" />
      <Propiedades
        loading={loading}
        setLoading={setLoading}
        regionFilter={regionFilter}
        error={error}
        handletipoBusquedaChange={handletipoBusquedaChange}
        handleBanos={handleBanos}
        handleHabitaciones={handleHabitaciones}
        setRegion={setRegion}
        handleChangeRegion={handleChangeRegion}
        handleChangeAgente={handleChangeAgente}
        region={region}
        properties={properties}
        users={users}
        handleUrl={handleUrl}
        agenteFilter={agenteFilter}
        setAgenteFilter={setAgenteFilter}
        tipoFilter={tipoFilter}
        habitacionFilter={habitacionFilter}
        banosFilter={banosFilter}
        regionFilter2={regionFilter2}
        matchFilter={matchFilter}
        handleReset={handleReset}
      />

      <PaginatedItems
        properties={properties}
        url={url}
        setUrl={setUrl}
        setLoading={setLoading}
        handleReset={handleReset}
        loading={loading}
      />
      <div id="agentes">
        <SimpleSlider users={users} />
        <UsersGrid users={users} />
      </div>
      <div className=" d-md-none sticky-bottom d-flex justify-content-center ps-5 align-items-center pt-2 bg-white py-2 ">
        <p className=" mt-3 ">
          <b>Contáctanos por Whatsapp</b>
        </p>
        <a
          className=""
          href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
          target="_blank"
        >
          <img
            className="ms-2"
            src={whatsapp}
            alt=""
            style={{ maxWidth: "8vh" }}
          />
        </a>
      </div>
      {/* <div className="  sticky-bottom d-inline bg-white "> */}
      <button className=" whatsapp ">
        <a
          className="ocultar"
          href="https://api.whatsapp.com/send?phone=65255567&text=Hola,%20te%20saluda%20Juan%20Severino,%20c%C3%B3mo%20puedo%20ayudarte"
          target="_blank"
        >
          <img
            className="  "
            src={whatsapp}
            alt=""
            style={{ maxWidth: "10vh" }}
          />
        </a>
      </button>
    </div>
  );
};

export default Homepage;
